<app-sidenav [usuario]="usuario"></app-sidenav>
<div class="container">
  <div class="search">
    <div class="productos">
      <label>Productos</label>
      <ng-select class="custom" [items]="productos" bindLabel="nombre" notFoundText="No hay productos con ese nombre"
        [(ngModel)]="busquedaActiva.idproducto">
      </ng-select>
    </div>
    <div class="clientes">
      <label>Cliente</label>
      <ng-select class="custom" [items]="clientes" bindLabel="nombre" notFoundText="No hay nadie con ese nombre"
        [(ngModel)]="busquedaActiva.idCliente">
      </ng-select>
    </div>
    <div class="tipo">
      <label>Congelado O Fresco</label>
      <ng-select class="custom" [items]="tipo" bindLabel="nombre" notFoundText="No hay nadie con ese nombre"
        [(ngModel)]="busquedaActiva.tipo">
      </ng-select>
    </div>
    <div class="tipo">
      <label>Desde</label>
      <input type="date" id="form-desde" [(ngModel)]="busquedaActiva.desde" class="form-control" mdbInput mdbValidate />
      <label>Hasta</label>
      <input type="date" id="form-hasta" [(ngModel)]="busquedaActiva.hasta" class="form-control" mdbInput mdbValidate />
    </div>
    <div class="buscar">
      <button (click)="buscar()">Buscar</button>
    </div>
  </div>
  <div class="tableResponseProductos">
    <table mdbTable class="z-depth-1" id="tablePrint">
      <thead>
        <tr>
          <th *ngFor="let head of headElements; let i = index" scope="col" class="text-center">
            {{ head }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of respuesta; let i = index" class="text-center"
          [ngClass]="{ desactivado: data.desactivado == 1 }">
          <td> {{ data.id }} </td>
          <td> {{ data.tipoSalida }} </td>
          <td> {{ data.usuario }} </td>
          <td> {{ data.cliente }} </td>
          <td> {{ data.fecha }} </td>
          <td>
            <mdb-icon mdbTooltip="Modificar {{data.tipoSalida}}" placement="right" fas icon="eye"
              (click)='openModal(data.id,data.tipoSalida)' class="green-text pr-3 pointer"></mdb-icon>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>