<app-sidenav [usuario]="usuario"></app-sidenav>
<div class="container">
  <div class="search">
    <div class="productos">
      <label>Productos</label>
      <ng-select class="custom" [items]="productos" bindLabel="nombre" notFoundText="No hay productos con ese nombre"
        [(ngModel)]="busquedaActiva.idproducto">
      </ng-select>
    </div>
    <div class="clientes">
      <label>Cliente</label>
      <ng-select class="custom" [items]="clientes" bindLabel="nombre" notFoundText="No hay nadie con ese nombre"
        [(ngModel)]="busquedaActiva.idCliente">
      </ng-select>
    </div>
    <div class="buscar">
      <button (click)="buscar()">Buscar</button>
    </div>
  </div>
  <div class="tableResponseProductos">
    <table mdbTable class="z-depth-1" id="tablePrint">
      <thead>
        <tr>
          <th *ngFor="let head of headElements; let i = index" scope="col" class="text-center">
            {{ head }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of respuesta; let i = index" class="text-center"
          [ngClass]="{ desactivado: data.desactivado == 1 }">
          <td> {{data.nombreCliente }} </td>
          <td> {{data.nombre}} </td>
          <td> {{data.stockFresco}} Kg </td>
          <td> {{data.stockCongelado}} Kg </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>