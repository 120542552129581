import {  Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent {

  @Input() usuario: any;
  constructor(private router: Router) { }
  user 
  ngOnInit(){
    this.user = this.usuario;
  }
  logOut(){
    console.log('sale')
    this.router.navigate(['/login']);
  }

}
