import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MdbTableDirective } from 'angular-bootstrap-md';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Usuarios } from 'src/app/interfaces/usuarios';
import { UsuariosService } from 'src/app/services/usuarios.service';

@Component({
  selector: 'app-usuario',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.scss']
})
export class UsuariosComponent implements OnInit {

  headElements = ['ID', 'Nombre', 'Rol','Acciones'];
  usuarios: Array<Usuarios> = [];
  searchItemsTableName:Array<string> = ['nombre','rol']
  usuariosForm: FormGroup;
  searchText: string = '';
  previous: string;
  full = false;
  type = 'Guardar';
  usuarioBaja :any;
  showLogoImage = false;
  usuario = JSON.parse(localStorage.getItem('usuario'));
  @ViewChild(MdbTableDirective, {static: true}) mdbTable:MdbTableDirective;

  constructor(private usuariosServices: UsuariosService, private toastr: ToastrService, private cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.recuperarUsuarios();
    this.usuariosForm = new FormGroup({
      idusuario: new FormControl(null),
      nombre: new FormControl('',Validators.required),
      rol: new FormControl('admin'),
      password: new FormControl(''),
    });
  }

  searchItems() {
    const prev = this.mdbTable.getDataSource();
    if (!this.searchText) {
        this.mdbTable.setDataSource(this.previous);
        this.usuarios = this.mdbTable.getDataSource();
    }
    if (this.searchText) {
        this.usuarios = this.mdbTable.searchLocalDataByMultipleFields(this.searchText, this.searchItemsTableName);
        this.mdbTable.setDataSource(prev);
    }
  }

  onEdit(el){
    console.log(el);
    this.usuariosForm.controls['idusuario'].setValue(el.idusuario);
    this.usuariosForm.controls['nombre'].setValue(el.nombre);
    this.usuariosForm.controls['rol'].setValue(el.rol);
    this.usuariosForm.controls['password'].setValue(el.password);
  }

  printTable(){
      this.showLogoImage = true;
      this.cdr.detectChanges()
      window.print();
      this.showLogoImage = false;
  }

  get usuariosABMNombre() {
    return this.usuariosForm.get('nombre');
  }

  get usuariosABMPassword() {
    return this.usuariosForm.get('password');
  }

  get usuariosABMRol() {
    return this.usuariosForm.get('rol');
  }

  reset(){
    this.usuariosForm.reset();
  }

  changeType(type){
    this.type = type;
  }

  altaUsuario(){
    if(this.type == 'Guardar'){
      let values = this.usuariosForm.value;
      delete values.idproducto;
      this.usuariosServices.alta(this.usuariosForm.value).subscribe((datos:any) => {
        this.toastr.success('', `El usuario ${datos.nombre} fue guardado exitosamente`);
        this.recuperarUsuarios();
        this.reset();
      });
    }else if(this.type == 'Actualizar'){
      this.usuariosServices.update(this.usuariosForm.value).subscribe(() => {
        this.toastr.success('', `El usuario fue actualizado exitosamente`);
        this.recuperarUsuarios();
        this.reset();
      });
    }else if(this.type == 'Baja'){
      this.usuarioBaja.desactivado = 1;
      this.usuariosServices.update(this.usuarioBaja).subscribe(() => {
      this.toastr.success('', `El usuario fue desactivado exitosamente`);
      this.recuperarUsuarios();
      this.reset();
    });
    }else if(this.type == 'Alta'){
      this.usuarioBaja.desactivado = 0;
      this.usuariosServices.update(this.usuarioBaja).subscribe(() => {
        this.toastr.success('', `El usuario fue activado exitosamente`);
        this.recuperarUsuarios();
        this.reset();
      });
    }
  }

  asignarModificacion(cliente){
    this.usuarioBaja = cliente;
  }


  recuperarUsuarios(){
    this.usuariosServices.recuperarTodos().subscribe((datos:Array<Usuarios>) => {
      this.usuarios = datos;
      this.mdbTable.setDataSource(this.usuarios);
      this.previous = this.mdbTable.getDataSource();
    });
  }
}
