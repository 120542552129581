<app-sidenav [usuario]="usuario"></app-sidenav>
<div class="container">
  <div class="header">
    <img src="../../../assets/images/logoNegro.jpg" style="width: 35% ;height: 100%" class="img-fluid"
      alt="Responsive image" />
    <div class="datos">
      <p>Ortiz de Zárate 3237</p>
      <p>Tel/Fax 0054 223 4891336</p>
      <p>E-mail: ichibansa@outlook.com</p>
      <p>7600 Mar del Plata - Argentina</p>
    </div>
    <div class="fecha">Fecha {{date}} </div>
    <div class="fecha">Ingreso N° {{nextId}} </div>
  </div>
  <select type="select" class="form-control d-print-none" id="cantidad" (ngModelChange)="onPropertyChange($event)"
    [formControl]="ingresoTipoPropiedad" mdbInput required>
    <option value="null" default>Tipo de propiedad *</option>
    <option value="propios">Propios</option>
    <option value="delCliente">Del Cliente</option>
  </select>
  <div class="datosCliente" *ngIf="ingresoTipoPropiedad.value !== null">
    <ng-select class="custom" [items]="
          ingresoTipoPropiedad.value !== null &&
          ingresoTipoPropiedad.value == 'propios'
            ? proveedores
            : clientes
        " bindLabel="nombre" [ngClass]="{ 'onPrintText bold addBorderRadius': printing }"
      notFoundText="No hay nadie con ese nombre" onChange="changeValue(change)" [(ngModel)]="selectedProveedor"
      (ngModelChange)="onChange($event)">
    </ng-select>
    <input type="text" id="form-domicilio" class="form-control" placeholder="Domicilio" [formControl]="ingresoDireccion"
      mdbInput [ngClass]="{ 'onPrintText bold': printing }" readonly mdbValidate />
    <input type="text" id="form-telefono" class="form-control" [ngClass]="{ 'onPrintText bold': printing }" mdbInput
      placeholder="Telefono" [formControl]="ingresoTelefono" readonly mdbValidate />
    <button type="button" mdbBtn color="black" (click)="altaModal.show()" class="d-print-none">
      <mdb-icon mdbTooltip="Agregar Producto" placement="left" fas color="black" icon="edit"
        class="white-text pr-3 pointer"></mdb-icon>
      Agregar Producto
    </button>
  </div>
  <div class="productosTable">
    <table mdbTable class="z-depth-1 table-bordered productosTabla" id="tableIngresoProductsPrint"
      [ngClass]="{ 'onPrintText': printing  }">
      <thead>
        <tr>
          <th *ngFor="let head of headElementsIngreso; let i = index"
            [ngClass]="{ 'd-print-none': head == 'Acciones', 'onPrintText': printing }" scope="col"
            class="text-center bold">
            {{ head }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let prod of productosAIngresar; let i = index" class="text-center"
          [ngClass]="{ desactivado: prod.desactivado == 1 , 'onPrintText removePaddingPrinting': printing }">
          <td class="black-text numero" [ngClass]="{ 'onPrintText removePaddingPrinting': printing }">
            <input type="text" [(ngModel)]="prod.bultos" class="form-control" id="idcliente"
              [ngClass]="{'noBorder': printing  }" NumericInput mdbInput />
          </td>
          <td class="numero" [ngClass]="{ 'onPrintText removePaddingPrinting': printing }">
            <input type="text" [(ngModel)]="prod.kgXBultos" class="form-control" id="kgXBultos"
              [ngClass]="{ 'noBorder': printing  }" NumericInput mdbInput />
          </td>
          <th scope="row" class="numero"
            [ngClass]="{ 'onPrintText removePaddingPrinting': printing, 'noBorder': printing  }">
            {{ prod.kgXBultos != 0 && prod.bultos != 0 ?  prod.kgXBultos * prod.bultos + 'Kg' : '-'}}
          </th>
          <th scope="row" class="texto-largo"
            [ngClass]="{ 'onPrintText removePaddingPrinting': printing, 'noBorder': printing  }">{{ prod.nombre }}</th>
          <td class="numero" [ngClass]="{ 'onPrintText removePaddingPrinting': printing  }">
            <select type="select" [(ngModel)]="prod.esCongelado" class="form-control" id="esCongelado"
              [ngClass]="{ 'noBorder': printing }" mdbInput>
              <option value="null" default>Seleccione una opción</option>
              <option value="true">Congelado</option>
              <option value="false">Fresco</option>
            </select>
          </td>
          <td class="texto-corto" [ngClass]="{ 'onPrintText removePaddingPrinting': printing  }">
            <input type="text" [ngClass]="{ 'noBorder': printing }" [(ngModel)]="prod.tipoDeEnvase" class="form-control"
              id="tipoDeEnvase" mdbInput />
          </td>
          <td class="texto-corto" [ngClass]="{ 'onPrintText removePaddingPrinting': printing  }">
            <input type="text" [(ngModel)]="prod.amparadoXSanitarioN" class="form-control" id="amparadoXSanitarioN"
              NumericInput mdbInput />
          </td>
          <td class="actions d-print-none">
            <div>
              <mdb-icon mdbTooltip="Remover Producto" placement="right" color="black" fas (click)="removerProduct(i)"
                icon="minus-circle" class="red-text pr-3 pointer d-print-none"></mdb-icon>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="datosExtra container" [ngClass]="{ 'removePadding': printing }">
    <div class="primerRenglon">
      <div class="palletsDiv d-print-none">
        <div class=" mb-3">
          <input mdbCheckbox class="form-check-input " type="checkbox" value="" [formControl]="ingresoPallets"
            id="pallets" />
          <label class="form-check-label " for="Pallets"> Pallets </label>
        </div>
      </div>
      <div class="input-group mb-3">
        <span class="input-group-text bold" [ngClass]="{ 'withBorder': printing }">Pallets</span>
        <input type="text" [formControl]="ingresoCantidad" aria-label="Pallets" [ngClass]="{ 'withBorder': printing }"
          class="form-control" placeholder="Cantidad de pallets" id="cantidad2" [readOnly]="!ingresoPallets.value"
          NumericInput mdbInput />
      </div>
    </div>
    <div class="segundoRenglon">
      <div class="input-group mb-3">
        <span class="input-group-text bold" [ngClass]="{ 'withBorder': printing }">Patente *</span>
        <input type="text" [formControl]="ingresoChapa" aria-label="Patente" class="form-control" placeholder="Patente"
          id="Patente" [ngClass]="{ 'withBorder': printing }" mdbInput mdbValidate />
        <mdb-error *ngIf="
            ingresoChapa?.invalid && isSubmit
            ">El campo Patente es requerido</mdb-error>
      </div>
      <div class="input-group mb-3">
        <span class="input-group-text bold" [ngClass]="{ 'withBorder': printing }">HAB. SENASA</span>
        <input type="text" [formControl]="ingresoHabSenasa" class="form-control" placeholder="HAB. SENASA"
          [ngClass]="{ 'withBorder': printing }" id="habSenasa" mdbInput />
      </div>
      <div class="input-group mb-3">
        <span class="input-group-text bold" [ngClass]="{ 'withBorder': printing }">Precintos</span>
        <input type="text" [formControl]="ingresoPrecintos" class="form-control" [ngClass]="{ 'withBorder': printing }"
          placeholder="Precintos" id="precintos" mdbInput />
      </div>
    </div>
    <div class="tercerRenglon">
      <div class="input-group mb-3">
        <span class="input-group-text bold" [ngClass]="{ 'withBorder': printing }">Chofer *</span>
        <input type="text" [formControl]="ingresoChofer" [ngClass]="{ 'withBorder': printing }" class="form-control"
          placeholder="Chofer" id="chofer" mdbValidate mdbInput />
        <mdb-error *ngIf="
            ingresoChofer?.invalid && isSubmit
            ">El campo Chofer es requerido</mdb-error>
      </div>
      <div class="input-group mb-3">
        <span class="input-group-text bold" [ngClass]="{ 'withBorder': printing }">DNI</span>
        <input type="text" [formControl]="ingresoDni" class="form-control" [ngClass]="{ 'withBorder': printing }"
          placeholder="DNI" id="dni" mdbInput />
      </div>
    </div>
    <div class="input-group mb-3">
      <span class="input-group-text bold" [ngClass]="{ 'withBorder': printing }">Observaciones</span>
      <textarea [formControl]="ingresoObservaciones" class="form-control" [ngClass]="{ 'withBorder': printing }"
        placeholder="Observaciones" id="observaciones" mdbInput></textarea>
    </div>

    <div class="firmas">
      <span class="firma bold"> Firma del encargado </span>
      <span class="firma bold"> Firma Conforme </span>
      <span class="firma bold"> Aclaracion </span>
    </div>
  </div>
  <div class="actionsTable d-print-none">
    <button type="button" mdbBtn color="primary" class="relative waves-light" (click)="crearIngreso(true)"
      mdbWavesEffect>
      Crear e Imprimir Ingreso
    </button>
    <button type="submit" mdbBtn color="primary" class="relative waves-light" mdbWavesEffect
      (click)="crearIngreso(false)">
      Crear Ingreso
    </button>
  </div>
</div>

<!-- INICIO MODAL ALTA PRODUCTO -->
<div mdbModal #altaModal="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myaltaModalLabel"
  aria-hidden="true" style="overflow: auto !important;">
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="altaModal.hide()">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body mx-3">
        <div>
          <div class="container" style="background-color: white">
            <div class="row d-print-none">
              <div class="col-md-12 mx-auto">
                <div class="md-form">
                  <input type="text" [(ngModel)]="searchText" (input)="searchItems()" class="form-control" id="search"
                    mdbInput />
                  <label for="search">Buscar</label>
                </div>
              </div>
            </div>
            <table mdbTable #productosTable="mdbTable" class="z-depth-1" id="tablePrint">
              <thead>
                <tr>
                  <th *ngFor="let head of headElementsProductos; let i = index" scope="col" class="text-center">
                    {{ head }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let el of productos; let i = index" class="text-center"
                  [ngClass]="{ desactivado: el.desactivado == 1 }">
                  <th scope="row">{{ el.idproducto }}</th>
                  <td class="black-text">{{ el.nombre }}</td>
                  <td class="black-text">{{ el.stockFresco }}Kg</td>
                  <td class="black-text">{{ el.stockCongelado }}Kg</td>
                  <td class="actions d-print-none">
                    <div>
                      <button *ngIf="el.desactivado != 1 && !(ingresoCliente.value == '')" type="button" mdbBtn
                        color="black" (click)="agregarProducto(el); altaModal.hide()">
                        <mdb-icon mdbTooltip="Agregar Producto" placement="left" fas color="black" icon="edit"
                          class="white-text pr-3 pointer"></mdb-icon>
                        Agregar producto
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- FIN MODAL ALTA PRODUCTO -->