import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HistorialService {

  url: string;
  constructor(private http: HttpClient) {
    this.url = window.location.origin === 'http://localhost:4200' ? 'http://localhost/database/' : 'https://sistema.ichibansa.com.ar/database/';
  }

  historial(historial) {
    return this.http.post(`${this.url}historial/getAll.php`, JSON.stringify(historial));
  }

  busquedaxId(id, ioe) {
    return this.http.post(`${this.url}historial/getById.php`, JSON.stringify({ id, ioe }));
  }

  actualizarIngreso(actualizacion) {
    return this.http.post(`${this.url}ingreso/update.php`, JSON.stringify(actualizacion));
  }

  actualizarEgreso(actualizacion) {
    return this.http.post(`${this.url}egreso/update.php`, JSON.stringify(actualizacion));
  }

}
