import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ProductosService {

  url : string;
  constructor(private http: HttpClient) {
    this.url = window.location.origin === 'http://localhost:4200' ?  'http://localhost/database/' : 'https://sistema.ichibansa.com.ar/database/';
  
  }

  recuperarTodos() {
    return this.http.get(`${this.url}productos/get.php`);
  }

  alta(producto) {
    return this.http.post(`${this.url}productos/post.php`, JSON.stringify(producto));    
  }

  baja(codigo:number) {
    return this.http.get(`${this.url}productos/baja.php?codigo=${codigo}`);
  }
  
  desactivar(producto) {
    return this.http.post(`${this.url}productos/desactivar.php`, JSON.stringify(producto));    
  } 

  activar(producto) {
      return this.http.post(`${this.url}productos/activar.php`, JSON.stringify(producto));    
  } 

  update(producto) {
    return this.http.put(`${this.url}productos/update.php`, JSON.stringify(producto));    
  }

  seleccionar(codigo:number) {
    return this.http.get(`${this.url}productos/seleccionar.php?codigo=${codigo}`);
  }

  crearIngreso(ingreso){
    return this.http.post(`${this.url}ingreso/post.php`, JSON.stringify(ingreso));
  }

  crearEgreso(egreso){
    return this.http.post(`${this.url}egreso/post.php`, JSON.stringify(egreso));
  }
}