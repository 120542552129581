import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Location } from '@angular/common';
import { Login } from '../interfaces/login';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  url : string;
  constructor(private http: HttpClient) {
    this.url = window.location.origin === 'http://localhost:4200' ?  'http://localhost/database/' : 'https://sistema.ichibansa.com.ar/database/';
   }

  ingresar(usuario: Login) {
    return this.http.post(`${this.url}login/post.php`, JSON.stringify(usuario));    
  } 

}