import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ProveedoresService {

  url : string;
  constructor(private http: HttpClient) {
    this.url = window.location.origin === 'http://localhost:4200' ?  'http://localhost/database/' : 'https://sistema.ichibansa.com.ar/database/';
  }
  

  recuperarTodos() {
    return this.http.get(`${this.url}proveedores/get.php`);
  }

  alta(cliente) {
    return this.http.post(`${this.url}proveedores/post.php`, JSON.stringify(cliente));    
  }

  baja(codigo:number) {
    return this.http.get(`${this.url}proveedores/baja.php?codigo=${codigo}`);
  }
  
  desactivar(cliente) {
    return this.http.post(`${this.url}proveedores/desactivar.php`, JSON.stringify(cliente));    
  } 

  activar(cliente) {
      return this.http.post(`${this.url}proveedores/activar.php`, JSON.stringify(cliente));    
  } 

  update(cliente) {
    return this.http.put(`${this.url}proveedores/update.php`, JSON.stringify(cliente));    
  }

  seleccionar(codigo:number) {
    return this.http.get(`${this.url}proveedores/seleccionar.php?codigo=${codigo}`);
  }
}