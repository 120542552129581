import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MDBModalRef, MdbTableDirective } from 'angular-bootstrap-md';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { Productos } from 'src/app/interfaces/productos';
import { ProductosService } from 'src/app/services/productos.service';
import { ToastrService } from 'ngx-toastr';
import { IEgreso, IProductosXEgreso } from 'src/app/interfaces/egreso';
import { ClientesService } from 'src/app/services/clientes.service';
import { Clientes } from 'src/app/interfaces/clientes';
import { ProveedoresService } from 'src/app/services/proveedores.service';
import { SearchService } from 'src/app/services/search.service';
import { Busqueda } from 'src/app/interfaces/busqueda';

@Component({
  selector: 'egreso',
  templateUrl: './egreso.component.html',
  styleUrls: ['./egreso.component.scss']
})
export class EgresoComponent implements OnInit {
  headElementsProductos = ['ID', 'Nombre', 'Stock Fresco', 'Stock Congelado', 'Acciones'];
  // headElementsEgreso = ['Producto', 'Bultos', 'Kg Por Bultos', 'Congelado o Fresco', 'Descripcion', 'Tipo de Envase', 'Temp.', 'Amparado x Sanitario N°', 'Acciones'];
  headElementsEgreso = ['Bultos', 'Kg Por Bultos', 'Total', 'Producto', 'Congelado o Fresco', 'Tipo de Envase', 'Trazabilidad', 'Acciones'];
  productos: Array<Productos> = [];
  egresos: Array<IEgreso> = [];
  productosAEgresar: Array<IProductosXEgreso> = [];
  searchItemsTableName: Array<string> = ['nombre', 'idproducto']
  egresoForm: FormGroup;
  searchText: string = '';
  previous: string;
  onlyPrice = false;
  onlyStock = false;
  full = false;
  type = 'Guardar';
  productoBaja: any;
  showLogoImage = false;
  producto: Array<any>;
  proveedores: Array<Clientes>;
  clientes: Array<Clientes>;
  selectedProveedor: any;
  esCliente = false;
  printing = false;
  busquedaActiva: Busqueda = {};
  date = '';
  tipoPropiedad: string = '';
  nextId;
  isSubmit = false;
  usuario = JSON.parse(localStorage.getItem('usuario'));
  @ViewChild('productosTable', { static: true }) mdbTableProductos: MdbTableDirective;
  constructor(private searchService: SearchService, private productosService: ProductosService, private clienteService: ClientesService, private proveedorService: ProveedoresService, private toastr: ToastrService, private cdr: ChangeDetectorRef) {
  }

  getDate() {
    let today: any = new Date();
    let dd: any = today.getDate();

    let mm = today.getMonth() + 1;
    let yyyy = today.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }

    if (mm < 10) {
      mm = '0' + mm;
    }
    return today = dd + '-' + mm + '-' + yyyy;
  }

  ngOnInit() {
    this.searchService.getNextId('egreso').subscribe((response: any) => {
      this.nextId = response.AUTO_INCREMENT;
    })
    this.date = this.getDate()
    this.recuperarProductos();
    this.recuperarClientes();
    this.egresoForm = new FormGroup({
      pallets: new FormControl(false),
      telefono: new FormControl(''),
      cliente: new FormControl('', Validators.required),
      direccion: new FormControl(''),
      tipoPropiedad: new FormControl(null, Validators.required),
      cantidad: new FormControl(null),
      chapa: new FormControl('', Validators.required),
      chofer: new FormControl('', Validators.required),
      habSenasa: new FormControl(''),
      precintos: new FormControl(''),
      dni: new FormControl(''),
      observaciones: new FormControl(''),
      usuario: new FormControl(this.usuario.idusuario),
    })
  }

  changeType(type) {
    this.type = type;
  }

  removerProduct(index) {
    this.productosAEgresar.splice(index, 1);
  }

  searchItems() {
    const prev = this.mdbTableProductos.getDataSource();
    if (!this.searchText) {
      this.mdbTableProductos.setDataSource(this.previous);
      this.productos = this.mdbTableProductos.getDataSource();
    }
    if (this.searchText) {
      this.productos = this.mdbTableProductos.searchLocalDataByMultipleFields(this.searchText, this.searchItemsTableName);
      this.mdbTableProductos.setDataSource(prev);
    }
  }

  agregarProducto(producto) {
    let newProducto: IProductosXEgreso = new IProductosXEgreso();
    newProducto.idproducto = producto.idproducto;
    newProducto.nombre = producto.nombre;
    this.productosAEgresar.push(newProducto);
  }

  onChange(event) {
    this.egresoForm['controls'].direccion.setValue(event.direccion);
    this.egresoForm['controls'].telefono.setValue(event.telefono);
    this.egresoForm['controls'].cliente.setValue(event.idcliente);
    this.cdr.detectChanges()
    this.recuperarProductos(this.tipoPropiedad === 'delCliente')
  }

  onEdit(el) {
  }

  onDelete() { }

  printTable() {
    window.print()
  }


  crearEgreso(imprimir) {
    this.isSubmit = true;
    if (!this.egresoForm.valid) {
      this.toastr.error('Por favor, complete todos los campos marcados con *');
      return;
    }
    if (!this.productosAEgresar.length) {
      this.toastr.error('Por favor, ingrese productos')
      return;
    }
    this.printing = true;
    this.cdr.detectChanges()
    let egreso = {
      "egresoForm": this.egresoForm.value,
      "productosAIngresar": this.productosAEgresar
    }
    this.productosService.crearEgreso(egreso).subscribe(() => {
      if (imprimir) {
        this.printTable();
      }
      this.isSubmit = false;
      this.egresoForm.reset()
      this.productosAEgresar = [];
      this.printing = false;
    })
  }

  onPropertyChange(value) {
    this.tipoPropiedad = value;
  }

  get egresoPallets() {
    return this.egresoForm.get('pallets');
  }

  get egresoCantidad() {
    return this.egresoForm.get('cantidad');
  }
  get egresoCliente() {
    return this.egresoForm.get('cliente');
  }

  get egresoDireccion() {
    return this.egresoForm.get('direccion');
  }

  get egresoTelefono() {
    return this.egresoForm.get('telefono');
  }

  get egresoTipoPropiedad() {
    return this.egresoForm.get('tipoPropiedad');
  }

  get egresoChapa() {
    return this.egresoForm.get('chapa');
  }

  get egresoHabSenasa() {
    return this.egresoForm.get('habSenasa');
  }

  get egresoPrecintos() {
    return this.egresoForm.get('precintos');
  }

  get egresoChofer() {
    return this.egresoForm.get('chofer');
  }

  get egresoDni() {
    return this.egresoForm.get('dni');
  }

  get egresoObservaciones() {
    return this.egresoForm.get('observaciones');
  }

  reset() {
    this.egresoForm.reset();
  }

  altaProducto() {
  }

  recuperarProductos(buscarCliente: boolean = false) {
    if (buscarCliente) {
      let busqueda = {
        idcliente: this.egresoForm['controls'].cliente.value,
        idproducto: null
      }
      this.searchService.busqueda(busqueda).subscribe((data: any) => {
        this.productos = data;
        this.mdbTableProductos.setDataSource(this.productos);
        this.previous = this.mdbTableProductos.getDataSource();
      })
    }
    else {
      this.productosService.recuperarTodos().subscribe((datos: Array<Productos>) => {
        this.productos = datos;
        this.mdbTableProductos.setDataSource(this.productos);
        this.previous = this.mdbTableProductos.getDataSource();
      });

    }
  }

  recuperarClientes() {
    this.proveedorService.recuperarTodos().subscribe((datos: Array<Clientes>) => {
      this.proveedores = datos;
    });
    this.clienteService.recuperarTodos().subscribe((datos: Array<Clientes>) => {
      this.clientes = datos

    })
  }
}