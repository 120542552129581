import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Login } from 'src/app/interfaces/login';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit{

  login : Login = {nombre: '', password: ''};

  constructor(private loginService: LoginService, private toastr: ToastrService, private router: Router) { }
  ngOnInit(): void {
    localStorage.removeItem('usuario');
  }

  enter(): void {
    this.loginService.ingresar(this.login).subscribe((datos:any) => {
      if(datos.length){
        if(datos[0].desactivado == 1){
          this.toastr.error('', `El usuario ${this.login.nombre} esta desactivado`);
          return;
        } 
        this.router.navigate(['/productos']);
        localStorage.setItem('usuario',JSON.stringify(datos[0]));
      }else {
        this.toastr.error('', `El usuario ${this.login.nombre} no existe o la contraseña es incorrecta`);
      }

    });
  }

}
