import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MdbTableDirective } from 'angular-bootstrap-md';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Productos } from 'src/app/interfaces/productos';
import { ProductosService } from 'src/app/services/productos.service';
import { ToastrService } from 'ngx-toastr';
import { IIngreso, IProductosXIngreso } from 'src/app/interfaces/ingreso';
import { ClientesService } from 'src/app/services/clientes.service';
import { Clientes } from 'src/app/interfaces/clientes';
import { ProveedoresService } from 'src/app/services/proveedores.service';
import { SearchService } from 'src/app/services/search.service';

@Component({
  selector: 'ingreso',
  templateUrl: './ingreso.component.html',
  styleUrls: ['./ingreso.component.scss']
})
export class IngresoComponent implements OnInit {
  headElementsProductos = ['ID', 'Nombre', 'Stock Fresco', 'Stock Congelado', 'Acciones'];
  //headElementsIngreso = ['Producto', 'Bultos', 'Kg Por Bultos', 'Congelado o Fresco', 'Descripcion', 'Tipo de Envase', 'Temp.', 'Amparado x Sanitario N°', 'Acciones'];
  headElementsIngreso = ['Bultos', 'Kg Por Bultos', 'Total', 'Producto', 'Congelado o Fresco', 'Tipo de Envase', 'Trazabilidad', 'Acciones'];
  productos: Array<Productos> = [];
  ingresos: Array<IIngreso> = [];
  productosAIngresar: Array<IProductosXIngreso> = [];
  searchItemsTableName: Array<string> = ['nombre', 'idproducto']
  ingresoForm: FormGroup;
  tipoPropiedad: string = '';
  productosPropios: Array<Productos>;
  searchText: string = '';
  previous: string;
  onlyPrice = false;
  onlyStock = false;
  full = false;
  type = 'Guardar';
  productoBaja: any;
  showLogoImage = false;
  producto: Array<any>;
  proveedores: Array<Clientes>;
  clientes: Array<Clientes>;
  selectedProveedor: any;
  printing = false;
  esCliente = false;
  date: any;
  isSubmit = false;
  nextId: any;
  usuario = JSON.parse(localStorage.getItem('usuario'));
  @ViewChild('productosTable', { static: true }) mdbTableProductos: MdbTableDirective;
  constructor(private searchService: SearchService, private productosService: ProductosService, private clienteService: ClientesService, private proveedorService: ProveedoresService, private toastr: ToastrService, private cdr: ChangeDetectorRef) {
  }

  getDate() {
    let today: any = new Date();
    let dd: any = today.getDate();

    let mm = today.getMonth() + 1;
    let yyyy = today.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }

    if (mm < 10) {
      mm = '0' + mm;
    }
    return today = dd + '-' + mm + '-' + yyyy;
  }

  ngOnInit() {
    this.searchService.getNextId('ingreso').subscribe((response: any) => {
      this.nextId = response.AUTO_INCREMENT;

    })
    console.log(this.usuario)
    this.date = this.getDate();
    this.recuperarProductos(this.tipoPropiedad === 'delCliente');
    this.recuperarClientes();
    this.ingresoForm = new FormGroup({
      pallets: new FormControl(false),
      telefono: new FormControl(''),
      cliente: new FormControl('', Validators.required),
      direccion: new FormControl(''),
      tipoPropiedad: new FormControl(null, Validators.required),
      cantidad: new FormControl(null),
      chapa: new FormControl('', Validators.required),
      habSenasa: new FormControl(''),
      precintos: new FormControl(''),
      chofer: new FormControl('', Validators.required),
      dni: new FormControl(''),
      observaciones: new FormControl(''),
      usuario: new FormControl(this.usuario.idusuario),
    })
  }

  changeType(type) {
    this.type = type;
  }

  searchItems() {
    const prev = this.mdbTableProductos.getDataSource();
    if (!this.searchText) {
      this.mdbTableProductos.setDataSource(this.previous);
      this.productos = this.mdbTableProductos.getDataSource();
    }
    if (this.searchText) {
      this.productos = this.mdbTableProductos.searchLocalDataByMultipleFields(this.searchText, this.searchItemsTableName);
      this.mdbTableProductos.setDataSource(prev);
    }
  }

  agregarProducto(producto) {
    let newProducto: IProductosXIngreso = new IProductosXIngreso();
    newProducto.idproducto = producto.idproducto;
    newProducto.nombre = producto.nombre;
    newProducto.kgXBultos = producto.bultos
    this.productosAIngresar.push(newProducto);
  }

  onChange(event) {
    this.ingresoForm['controls'].direccion.setValue(event.direccion);
    this.ingresoForm['controls'].telefono.setValue(event.telefono);
    this.ingresoForm['controls'].cliente.setValue(event.idcliente);
    this.recuperarProductos(this.ingresoTipoPropiedad.value === 'delCliente')
  }

  printTable() {
    window.print();
  }

  crearIngreso(imprimir) {
    this.isSubmit = true;
    if (!this.ingresoForm.valid) {
      this.toastr.error('Por favor, complete todos los campos marcados con *');
      return;
    }
    if (!this.productosAIngresar.length) {
      this.toastr.error('Por favor, ingrese productos')
      return;
    }

    this.printing = true;
    this.cdr.detectChanges()
    let ingreso = {
      "ingresoForm": this.ingresoForm.value,
      "productosAIngresar": this.productosAIngresar
    }
    this.productosService.crearIngreso(ingreso).subscribe(() => {
      if (imprimir) {
        this.printTable();
      }
      this.ingresoForm.reset()
      this.productosAIngresar = [];
      this.printing = false;
      this.isSubmit = false;
    })
  }

  removerProduct(index) {
    this.productosAIngresar.splice(index, 1);
  }

  onPropertyChange(value) {
    console.log(value);
  }

  get ingresoPallets() {
    return this.ingresoForm.get('pallets');
  }

  get ingresoCantidad() {
    return this.ingresoForm.get('cantidad');
  }
  get ingresoCliente() {
    return this.ingresoForm.get('cliente');
  }

  get ingresoDireccion() {
    return this.ingresoForm.get('direccion');
  }

  get ingresoTelefono() {
    return this.ingresoForm.get('telefono');
  }

  get ingresoTipoPropiedad() {
    return this.ingresoForm.get('tipoPropiedad');
  }

  get ingresoChapa() {
    return this.ingresoForm.get('chapa');
  }

  get ingresoHabSenasa() {
    return this.ingresoForm.get('habSenasa');
  }

  get ingresoPrecintos() {
    return this.ingresoForm.get('precintos');
  }

  get ingresoChofer() {
    return this.ingresoForm.get('chofer');
  }

  get ingresoDni() {
    return this.ingresoForm.get('dni');
  }

  get ingresoObservaciones() {
    return this.ingresoForm.get('observaciones');
  }

  reset() {
    this.ingresoForm.reset();
  }

  altaProducto() {
  }

  recuperarProductos(buscarCliente) {
    if (buscarCliente) {
      let busqueda = {
        idcliente: this.ingresoForm['controls'].cliente.value,
        idproducto: null
      }
      this.searchService.busqueda(busqueda).subscribe((data: any) => {
        data.forEach((producto) => {
          this.productosPropios.forEach((productoPropio) => {
            if (productoPropio.nombre === producto.nombre) {
              productoPropio.idproducto = producto.idproducto;
              productoPropio.stockCongelado = producto.stockCongelado;
              productoPropio.stockFresco = producto.stockFresco;
            } else {
              productoPropio.stockCongelado = '0';
              productoPropio.stockFresco = '0';
            }
          })
        })
        this.productosPropios = data;
        this.mdbTableProductos.setDataSource(this.productosPropios);
        this.previous = this.mdbTableProductos.getDataSource();
      })
    }
    else {
      this.productosService.recuperarTodos().subscribe((datos: Array<Productos>) => {
        this.productos = datos;
        this.productosPropios = datos;
        this.mdbTableProductos.setDataSource(this.productosPropios);
        this.previous = this.mdbTableProductos.getDataSource();
      });

    }
  }

  recuperarClientes() {
    this.proveedorService.recuperarTodos().subscribe((datos: Array<Clientes>) => {
      this.proveedores = datos;
    });
    this.clienteService.recuperarTodos().subscribe((datos: Array<Clientes>) => {
      this.clientes = datos

    })
  }
}