import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MDBModalRef, MdbTableDirective } from 'angular-bootstrap-md';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Productos } from 'src/app/interfaces/productos';
import { ProductosService } from 'src/app/services/productos.service';
import { ToastrService } from 'ngx-toastr';
import { ThisReceiver } from '@angular/compiler';

@Component({
  selector: 'productos',
  templateUrl: './productos.component.html',
  styleUrls: ['./productos.component.scss']
})
export class ProductosComponent implements OnInit {

  headElements;
  productos: Array<Productos> = [];
  searchItemsTableName: Array<string> = ['nombre', 'idproducto']
  productosForm: FormGroup;
  searchText: string = '';
  previous: string;
  onlyPrice = false;
  onlyStock = false;
  full = false;
  type = 'Guardar';
  productoBaja: any;
  showLogoImage = false;
  filter: string = 'todos';
  frescos;
  congelados;
  usuario = JSON.parse(localStorage.getItem('usuario'));
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective;

  constructor(private productosService: ProductosService, private toastr: ToastrService, private cdr: ChangeDetectorRef) {
  }

  public roundTo = (num: number, places: number) => {
    const factor = 10 ** places;
    return Math.round(num * factor) / factor;
  };

  ngOnInit() {
    this.recuperarProductos();
    if (this.usuario.rol !== 'capataz') {
      this.headElements = ['ID', 'Nombre', 'Stock Fresco', 'Precio Fresco', 'Subtotal Fresco', 'Bultos Congelado', 'Stock Congelado', 'Precio Congelado', 'Subtotal Congelado', 'Total', 'Acciones'];
      this.frescos = ['ID', 'Nombre', 'Bultos', 'Stock Fresco', 'Precio Fresco', 'Subtotal Fresco', 'Total', 'Acciones'];
      this.congelados = ['ID', 'Nombre', 'Bultos', 'Stock Congelado', 'Precio Congelado', 'Subtotal Congelado', 'Total', 'Acciones'];
    } else {
      this.frescos = ['ID', 'Nombre', 'Stock Fresco'];
      this.congelados = ['ID', 'Nombre', 'Stock Congelado'];
      this.headElements = ['ID', 'Nombre', 'Stock Fresco', 'Stock Congelado'];
    }
    this.productosForm = new FormGroup({
      idproducto: new FormControl(null),
      nombre: new FormControl('', Validators.required),
      precioFresco: new FormControl(0),
      precioCongelado: new FormControl(0),
      stockCongelado: new FormControl(0, Validators.required),
      stockFresco: new FormControl(0, Validators.required),
      bultos: new FormControl(null)
    });
  }

  getFloor(x) {
    return Math.floor(x);
  }

  changeType(type) {
    this.type = type;
  }


  searchItems() {
    const prev = this.mdbTable.getDataSource();
    if (!this.searchText) {
      this.mdbTable.setDataSource(this.previous);
      this.productos = this.mdbTable.getDataSource();
    }
    if (this.searchText) {
      this.productos = this.mdbTable.searchLocalDataByMultipleFields(this.searchText, this.searchItemsTableName);
      this.mdbTable.setDataSource(prev);
    }
  }

  onEdit(el) {
    this.productosForm.controls['idproducto'].setValue(el.idproducto);
    this.productosForm.controls['nombre'].setValue(el.nombre);
    this.productosForm.controls['precioFresco'].setValue(el.precioFresco);
    this.productosForm.controls['precioCongelado'].setValue(el.precioCongelado);
    this.productosForm.controls['stockCongelado'].setValue(el.stockCongelado);
    this.productosForm.controls['stockFresco'].setValue(el.stockFresco);
    this.productosForm.controls['bultos'].setValue(el.bultos);
  }

  onDelete() { }

  printTable(tipo) {
    if (tipo == 'full') {
      this.onlyPrice = false;
      this.onlyStock = false;
      this.full = false;
      this.showLogoImage = true;
      this.cdr.detectChanges()
      window.print();
      this.showLogoImage = false;
    } else if (tipo == 'precio') {
      this.onlyPrice = true;
      this.onlyStock = false;
      this.full = true;
      this.showLogoImage = true;
      this.cdr.detectChanges()
      window.print();
      this.showLogoImage = false;
    } else if (tipo == 'stock') {
      this.onlyPrice = false;
      this.onlyStock = true;
      this.full = true;
      this.showLogoImage = true;
      this.cdr.detectChanges()
      window.print();
      this.showLogoImage = false;
    }
  }

  get productosABMNombre() {
    return this.productosForm.get('nombre');
  }

  get productosABMPrecioFresco() {
    return this.productosForm.get('precioFresco');
  }

  get productosABMBultos() {
    return this.productosForm.get('bultos');
  }

  get productosABMPrecioCongelado() {
    return this.productosForm.get('precioCongelado');
  }

  get productosABMStockCongelado() {
    return this.productosForm.get('stockCongelado');
  }

  get productosABMStockFresco() {
    return this.productosForm.get('stockFresco');
  }

  reset() {
    this.productosForm.reset();
  }

  altaProducto() {
    if (this.type == 'Guardar') {
      let values = this.productosForm.value;
      delete values.idproducto;
      this.productosService.alta(this.productosForm.value).subscribe((datos: Productos) => {
        this.toastr.success('', `El producto ${datos.nombre} fue guardado exitosamente`);
        this.recuperarProductos();
        this.reset();
      });
    } else if (this.type == 'Actualizar') {
      this.productosService.update(this.productosForm.value).subscribe((datos: Productos) => {
        this.toastr.success('', `El producto fue actualizado exitosamente`);
        this.recuperarProductos();
        this.reset();
      });
    } else if (this.type == 'Baja') {
      this.productoBaja.desactivado = 1;
      this.productosService.update(this.productoBaja).subscribe((datos: Productos) => {
        this.toastr.success('', `El producto fue desactivado exitosamente`);
        this.recuperarProductos();
        this.reset();
      });
    } else if (this.type == 'Alta') {
      this.productoBaja.desactivado = 0;
      this.productosService.update(this.productoBaja).subscribe((datos: Productos) => {
        this.toastr.success('', `El producto fue activado exitosamente`);
        this.recuperarProductos();
        this.reset();
      });
    }
  }

  asignarModificacion(producto) {
    this.productoBaja = producto;
  }


  recuperarProductos() {
    this.productosService.recuperarTodos().subscribe((datos: Array<Productos>) => {
      this.productos = datos;
      this.mdbTable.setDataSource(this.productos);
      this.previous = this.mdbTable.getDataSource();
    });
  }
}