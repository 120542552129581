<app-sidenav [usuario]="usuario"></app-sidenav>
<div class="text-center" [ngClass]="{ showLogoImage: !showLogoImage }">
  <img src="../../../assets/images/logoNegro.jpg" style="width: 25% ; height: 95%;" class="img-fluid"
    alt="Responsive image" />
</div>
<div>
  <div class="container">
    <h1 class="text-white text-center d-print-none">Stocks</h1>

    <div>
      <div class="container" style="background-color: white">
        <div class="row d-print-none">
          <div class="col-md-12 mx-auto" style="display: flex; justify-content: space-between; align-items: center;">
            <div class="md-form">
              <input type="text" [(ngModel)]="searchText" (input)="searchItems()" class="form-control" id="search"
                mdbInput />
              <label for="search">Buscar</label>
            </div>
            <div>
              <select type="select" class="form-control d-print-none" id="cantidad" [(ngModel)]="filter" mdbInput>
                <option value="todos" default>Todos</option>
                <option value="congelados">Congelados</option>
                <option value="frescos">Frescos</option>
              </select>
            </div>
          </div>
        </div>
        <table mdbTable class="z-depth-1" id="tablePrint">
          <thead>
            <tr>
              <th
                *ngFor="let head of (filter === 'todos' ? headElements : (filter === 'congelados' ? congelados : frescos) ); let i = index"
                [ngClass]="{
                  'd-print-none':
                    ((head == 'Total' ||
                      head == 'Subtotal Congelado' ||
                      head == 'Subtotal Fresco' ||
                      head == 'Stock Congelado' ||
                      head == 'Stock Fresco') &&
                      onlyPrice) ||
                    head == 'Acciones' ||
                    ((head == 'Precio Congelado' ||
                      head == 'Precio Fresco' ||
                      head == 'Subtotal Fresco' ||
                      head == 'Subtotal Congelado' ||
                      head == 'Total') &&
                      onlyStock)
                }" scope="col" class="text-center">
                {{ head }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let el of productos; let i = index" class="text-center"
              [ngClass]="{ desactivado: el.desactivado == 1 }">
              <th scope="row">{{ el.idproducto }}</th>
              <td class="black-text">{{ el.nombre }}</td>
              <td *ngIf="filter === 'todos' || filter === 'frescos'" [ngClass]="{
                  'd-print-none': onlyPrice,
                  'red-text': el.stockFresco < 0
                }">
                {{ el.stockFresco }} Kg
              </td>
              <td [ngClass]="{ 'd-print-none': onlyStock }"
                *ngIf="usuario.rol != 'capataz' && filter === 'todos' || usuario.rol != 'capataz' && filter === 'frescos'">
                ${{ el.precioFresco }}
              </td>
              <td [ngClass]="{
                  'd-print-none': full,
                  'red-text': el.stockFresco < 0
                }"
                *ngIf="usuario.rol != 'capataz' && filter === 'todos' || usuario.rol != 'capataz' && filter === 'frescos'">
                ${{ roundTo(el.stockFresco * el.precioFresco, 2) }}
              </td>
              <td *ngIf="filter === 'todos' || filter === 'congelados'" [ngClass]="{
                  'd-print-none': onlyPrice
                }">{{ el.bultos !== null && el.bultos > 0 ? getFloor(el.stockCongelado / el.bultos) : null  }}</td>
              <td *ngIf="filter === 'todos' || filter === 'congelados'" [ngClass]="{
                  'd-print-none': onlyPrice,
                  'red-text': el.stockCongelado < 0
                }">
                {{ el.stockCongelado }} Kg
              </td>
              <td [ngClass]="{ 'd-print-none': onlyStock }"
                *ngIf="usuario.rol != 'capataz' && filter === 'todos' || usuario.rol != 'capataz' && filter === 'congelados'">
                ${{ el.precioCongelado }}
              </td>
              <td [ngClass]="{
                  'd-print-none': full,
                  'red-text': el.stockCongelado < 0
                }"
                *ngIf="usuario.rol != 'capataz' && filter === 'todos' || usuario.rol != 'capataz' && filter === 'congelados'">
                ${{ roundTo(el.stockCongelado * el.precioCongelado,2) }}
              </td>
              <td [ngClass]="{ 'd-print-none': full }" *ngIf="usuario.rol != 'capataz'">
                ${{ filter === 'todos' ?
                roundTo(el.stockFresco * el.precioFresco +
                el.stockCongelado * el.precioCongelado, 2) : (filter === 'congelados' ? roundTo(el.stockCongelado *
                el.precioCongelado,2) : roundTo(el.stockFresco * el.precioFresco, 2))
                }}
              </td>
              <td class="actions d-print-none" *ngIf="usuario.rol != 'capataz'">
                <div>
                  <mdb-icon mdbTooltip="Editar Producto" placement="left" (click)="
                      changeType('Actualizar'); onEdit(el); altaModal.show()
                    " fas icon="edit" class="green-text pr-3 pointer"></mdb-icon>
                </div>
                <div *ngIf="el.desactivado == 0 && usuario.rol != 'capataz'">
                  <mdb-icon mdbTooltip="Desactivar Producto" placement="right"
                    (click)="asignarModificacion(el); bajaModal.show()" fas icon="times"
                    class="green-text pr-3 pointer"></mdb-icon>
                </div>
                <div *ngIf="el.desactivado == 1 && usuario.rol != 'capataz'">
                  <mdb-icon mdbTooltip="Activar Producto" placement="right"
                    (click)="asignarModificacion(el); activarModal.show()" fas icon="check"
                    class="green-text pr-3 pointer"></mdb-icon>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="actionsTable d-print-none">
    <button type="button" mdbBtn color="primary" class="relative waves-light"
      (click)="changeType('Guardar'); altaModal.show()" mdbWavesEffect *ngIf="usuario.rol !== 'capataz'">
      Agregar nuevo producto
    </button>
    <button type="button" mdbBtn color="primary" class="relative waves-light" (click)="printTable('full')"
      mdbWavesEffect>
      Imprimir lista de productos
    </button>
    <button type="button" mdbBtn color="primary" class="relative waves-light" (click)="printTable('stock')"
      mdbWavesEffect>
      Imprimir solo stock
    </button>
    <button type="button" mdbBtn color="primary" class="relative waves-light" (click)="printTable('precio')"
      mdbWavesEffect *ngIf="usuario.rol != 'capataz'">
      Imprimir solo precios
    </button>
  </div>
</div>

<!-- INICIO MODAL ALTA PRODUCTO -->
<div mdbModal #altaModal="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myaltaModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="altaModal.hide()">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body mx-3">
        <div class="md-form mb-5">
          <input type="text" id="form-nombre" [formControl]="productosABMNombre" class="form-control" mdbInput
            mdbValidate />
          <label for="form-nombre">Nombre</label>
          <mdb-error *ngIf="
              productosABMNombre.invalid &&
              (productosABMNombre.dirty || productosABMNombre.touched)
            ">
          </mdb-error>
          <mdb-success *ngIf="
              productosABMNombre.valid &&
              (productosABMNombre.dirty || productosABMNombre.touched)
            ">
          </mdb-success>
        </div>

        <div class="md-form mb-4">
          <input placement="top" NumericInput [latestInputValue]="productosABMBultos" type="number" id="form-precio"
            [formControl]="productosABMBultos" class="form-control" mdbInput mdbValidate />
          <label for="form-precio">Bultos</label>
          <mdb-error *ngIf="
                      productosABMBultos.invalid &&
                      (productosABMBultos.dirty ||
                        productosABMBultos.touched)
                    ">
          </mdb-error>
          <mdb-success *ngIf="
                      productosABMBultos.valid &&
                      (productosABMBultos.dirty ||
                        productosABMBultos.touched)
                    ">
          </mdb-success>
        </div>

        <div class="md-form mb-4">
          <input mdbTooltip="Valor mínimo 0" placement="top" NumericInput [latestInputValue]="productosABMPrecioFresco"
            type="number" id="form-precio" [formControl]="productosABMPrecioFresco" class="form-control" mdbInput
            mdbValidate />
          <label for="form-precio">Precio Fresco</label>
          <mdb-error *ngIf="
              productosABMPrecioFresco.invalid &&
              (productosABMPrecioFresco.dirty ||
                productosABMPrecioFresco.touched)
            ">
          </mdb-error>
          <mdb-success *ngIf="
              productosABMPrecioFresco.valid &&
              (productosABMPrecioFresco.dirty ||
                productosABMPrecioFresco.touched)
            ">
          </mdb-success>
        </div>

        <div class="md-form mb-4">
          <input mdbTooltip="Valor mínimo 0" placement="top" NumericInput [latestInputValue]="productosABMStockFresco"
            type="number" id="form-precio" [formControl]="productosABMStockFresco" class="form-control" mdbInput
            mdbValidate />
          <label for="form-precio">Stock Fresco</label>
          <mdb-error *ngIf="
              productosABMStockFresco.invalid &&
              (productosABMStockFresco.dirty || productosABMStockFresco.touched)
            ">
          </mdb-error>
          <mdb-success *ngIf="
              productosABMStockFresco.valid &&
              (productosABMStockFresco.dirty || productosABMStockFresco.touched)
            ">
          </mdb-success>
        </div>

        <div class="md-form mb-4">
          <input mdbTooltip="Valor mínimo 0" placement="top" NumericInput
            [latestInputValue]="productosABMPrecioCongelado" type="number" id="form-precio"
            [formControl]="productosABMPrecioCongelado" class="form-control" mdbInput mdbValidate />
          <label for="form-precio">Precio Congelado</label>
          <mdb-error *ngIf="
              productosABMPrecioCongelado.invalid &&
              (productosABMPrecioCongelado.dirty ||
                productosABMPrecioCongelado.touched)
            ">
          </mdb-error>
          <mdb-success *ngIf="
              productosABMPrecioCongelado.valid &&
              (productosABMPrecioCongelado.dirty ||
                productosABMPrecioCongelado.touched)
            ">
          </mdb-success>
        </div>
        <div class="md-form mb-4">
          <input mdbTooltip="Valor mínimo 0" placement="top" NumericInput
            [latestInputValue]="productosABMStockCongelado" type="number" id="form-precio"
            [formControl]="productosABMStockCongelado" class="form-control" mdbInput mdbValidate />
          <label for="form-precio">Stock Congelados</label>
          <mdb-error *ngIf="
              productosABMStockCongelado.invalid &&
              (productosABMStockCongelado.dirty ||
                productosABMStockCongelado.touched)
            ">
          </mdb-error>
          <mdb-success *ngIf="
              productosABMStockCongelado.valid &&
              (productosABMStockCongelado.dirty ||
                productosABMStockCongelado.touched)
            ">
          </mdb-success>
        </div>
        <div class="modal-footer">
          <button type="button" mdbBtn color="secondary" class="waves-light" aria-label="Close"
            (click)="this.reset(); altaModal.hide()" mdbWavesEffect>
            Cancelar
          </button>
          <button type="button" mdbBtn color="primary" class="relative waves-light"
            (click)="altaProducto(); altaModal.hide()" mdbWavesEffect>
            {{ type }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- FIN MODAL ALTA PRODUCTO -->

<!-- INICIO MODAL BAJA PRODUCTO -->
<div mdbModal #bajaModal="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="mybajaModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="bajaModal.hide()">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body mx-3">
        <p>
          Desea desactivar el producto? Al hacerlo no podra realizar ingresos o
          egresos del mismo.
        </p>
        <div class="modal-footer">
          <button type="button" mdbBtn color="secondary" class="waves-light" aria-label="Close"
            (click)="this.reset(); bajaModal.hide()" mdbWavesEffect>
            No
          </button>
          <button type="button" mdbBtn color="primary" class="relative waves-light"
            (click)="changeType('Baja'); altaProducto(); bajaModal.hide()" mdbWavesEffect>
            Sí
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- FIN MODAL BAJA PRODUCTO -->

<!-- INICIO MODAL BAJA PRODUCTO -->
<div mdbModal #activarModal="mdbModal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="myactivarModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="activarModal.hide()">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body mx-3">
        <p>
          Desea activar el producto? Al hacerlo podra realizar ingresos o
          egresos del mismo.
        </p>
        <div class="modal-footer">
          <button type="button" mdbBtn color="secondary" class="waves-light" aria-label="Close"
            (click)="this.reset(); activarModal.hide()" mdbWavesEffect>
            No
          </button>
          <button type="button" mdbBtn color="primary" class="relative waves-light"
            (click)="changeType('Alta'); altaProducto(); activarModal.hide()" mdbWavesEffect>
            Sí
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- FIN MODAL BAJA PRODUCTO -->