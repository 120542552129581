import { ChangeDetectorRef, Component, EventEmitter, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BusquedaHistorial } from 'src/app/interfaces/busquedaHistorial';
import { Clientes } from 'src/app/interfaces/clientes';
import { Productos } from 'src/app/interfaces/productos';
import { ClientesService } from 'src/app/services/clientes.service';
import { HistorialService } from 'src/app/services/historial.service';
import { ProductosService } from 'src/app/services/productos.service';
import { ProveedoresService } from 'src/app/services/proveedores.service';
import { ModalComponent } from './modal/modal.component';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';

@Component({
  selector: 'app-historial',
  templateUrl: './historial.component.html',
  styleUrls: ['./historial.component.scss']
})
export class HistorialComponent implements OnInit {


  constructor(private clientService: ClientesService, private modalService: MdbModalService, private historialService: HistorialService, private productServices: ProductosService, private clienteService: ClientesService, private proveedorService: ProveedoresService, private toastr: ToastrService, private cdr: ChangeDetectorRef) { }
  ngOnInit(): void {
    this.clientService.recuperarTodos().subscribe((data: Array<Clientes>) => {
      this.clientes = data;
    })
    this.productServices.recuperarTodos().subscribe((data: Array<Productos>) => {
      this.productos = data;
    })
    this.historialService.historial(this.busqueda).subscribe((data) => {
      this.respuesta = data;
    })
  }


  productos: Array<any>;
  proveedores: Array<Clientes>;
  clientes: Array<Clientes>;
  selectedProveedor: any;
  esCliente = false;
  usuario = JSON.parse(localStorage.getItem('usuario'));
  showModal: EventEmitter<any> = new EventEmitter();
  modalRef: MdbModalRef<ModalComponent>;


  headElements: Array<string> = ['Id ', 'Tipo', 'Encargado', 'Cliente', 'Fecha', 'Acciones'];
  tipo: Array<string> = ['congelado', 'fresco'];
  busquedaActiva: BusquedaHistorial = {};
  respuesta: any;
  busqueda: any = {
    idcliente: false,
    idproducto: false,
    tipo: false,
    desde: false,
    hasta: false
  }

  buscar() {
    this.busqueda = {
      idcliente: this.busquedaActiva.idCliente?.idcliente || false,
      idproducto: this.busquedaActiva.idproducto?.idproducto || false,
      tipo: this.busquedaActiva.tipo || false,
      desde: this.busquedaActiva.desde || false,
      hasta: this.busquedaActiva.hasta || false
    }
    this.historialService.historial(this.busqueda).subscribe((data) => {
      this.respuesta = data;
    })
  }

  openModal(id, ioe) {
    const modalRef = this.modalService.open(ModalComponent, { data: { id, ioe }, modalClass: 'modal-xl modal-dialog-scrollable' });
  }
}
