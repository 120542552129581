import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Usuarios } from './app/interfaces/usuarios';
@Injectable()
export class GuestGuard implements CanActivate {
  constructor( private router: Router) {}
  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
      const usuario = localStorage.getItem('usuario')
        if(usuario) {

            return true;
        }
        this.router.navigate['/login']
        return false;
  }
}

@Injectable()
export class CanEnter implements CanActivate {
  constructor( private router: Router) {}
  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
      const usuario : Usuarios = JSON.parse(localStorage.getItem('usuario'))
        if(usuario.rol !== 'capataz') {
            return true;
        }
        this.router.navigate['/login']
        return false;
  }
}