import { ChangeDetectorRef, Component, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MdbTableDirective } from 'angular-bootstrap-md';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { ToastrService } from 'ngx-toastr';
import { BusquedaHistorial } from 'src/app/interfaces/busquedaHistorial';
import { Clientes } from 'src/app/interfaces/clientes';
import { IIngreso, IProductosXIngreso } from 'src/app/interfaces/ingreso';
import { Productos } from 'src/app/interfaces/productos';
import { ClientesService } from 'src/app/services/clientes.service';
import { HistorialService } from 'src/app/services/historial.service';
import { ProductosService } from 'src/app/services/productos.service';
import { ProveedoresService } from 'src/app/services/proveedores.service';
import { SearchService } from 'src/app/services/search.service';

@Component({
  selector: 'modal-modificacion',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {

  constructor(private searchService: SearchService,
    private historialService: HistorialService,
    private productServices: ProductosService, public modalRef: MdbModalRef<ModalComponent>, private clienteService: ClientesService, private proveedorService: ProveedoresService, private toastr: ToastrService, private cdr: ChangeDetectorRef) { }

  headElementsProductos = ['ID', 'Nombre', 'Stock Fresco', 'Stock Congelado', 'Acciones'];
  //headElementsIngreso = ['Producto', 'Bultos', 'Kg Por Bultos', 'Congelado o Fresco', 'Descripcion', 'Tipo de Envase', 'Temp.', 'Amparado x Sanitario N°', 'Acciones'];
  headElementsIngreso = ['Bultos', 'Kg Por Bultos', 'Total', 'Producto', 'Congelado o Fresco', 'Tipo de Envase', 'Trazabilidad', 'Acciones'];
  productos: Array<Productos> = [];
  ingresos: Array<IIngreso> = [];
  productosAIngresar: Array<IProductosXIngreso> = [];
  searchItemsTableName: Array<string> = ['nombre', 'idproducto']
  ingresoForm: FormGroup;
  searchText: string = '';
  previous: string;
  onlyPrice = false;
  onlyStock = false;
  full = false;
  printing = false;
  type = 'Guardar';
  productoBaja: any;
  showLogoImage = false;
  producto: Array<any>;
  proveedores: Array<Clientes>;
  clientes: Array<Clientes>;
  selectedProveedor: any;
  esCliente = false;
  usuario = JSON.parse(localStorage.getItem('usuario'));
  informacion: any;
  productosAnteriores: any;
  headElements: Array<string> = ['Id ', 'Tipo', 'Encargado', 'Cliente', 'Acciones'];
  tipo: Array<string> = ['congelado', 'fresco'];
  busquedaActiva: BusquedaHistorial = {};
  respuesta: any;
  nextId;
  isSubmit = false;
  busqueda: any = {
    idcliente: false,
    idproducto: false,
    tipo: false,
    desde: false,
    hasta: false
  }
  @ViewChild('productosTable', { static: true }) mdbTableProductos: MdbTableDirective;
  id: string;
  ioe: string;
  date: any;

  getDate() {
    let today: any = new Date();
    let dd: any = today.getDate();

    let mm = today.getMonth() + 1;
    let yyyy = today.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }

    if (mm < 10) {
      mm = '0' + mm;
    }
    return today = dd + '-' + mm + '-' + yyyy;
  }

  ngOnInit(): void {
    this.searchService.getNextId(this.ioe.toLowerCase()).subscribe((response: any) => {
      this.nextId = response.AUTO_INCREMENT;
    })
    this.date = this.getDate();
    this.productServices.recuperarTodos().subscribe((data: Array<Productos>) => {
      this.productos = data;
      this.historialService.historial(this.busqueda).subscribe((data) => {
        this.respuesta = data;
        this.recuperarClientes()
        this.historialService.busquedaxId(this.id, this.ioe).subscribe((data: any) => {
          this.informacion = data;
          this.ingresoForm = new FormGroup({
            pallets: new FormControl(data.datos.cantidad !== ''),
            telefono: new FormControl(data.cliente.telefono),
            cliente: new FormControl(data.cliente.idcliente, Validators.required),
            direccion: new FormControl(data.cliente.direccion),
            tipoPropiedad: new FormControl(data.datos.tipoDePropiedad, Validators.required),
            chofer: new FormControl(data.datos.chofer, Validators.required),
            chapa: new FormControl(data.datos.chapa, Validators.required),
            cantidad: new FormControl(data.datos.cantidad),
            habSenasa: new FormControl(data.datos.habSenasa),
            precintos: new FormControl(data.datos.precintos),
            dni: new FormControl(data.datos.dni),
            observaciones: new FormControl(data.datos.observaciones),
          })
          this.productosAIngresar = data.productos;
          this.productosAnteriores = this.productosAIngresar;
        })
      })
    })
  }

  removeProduct(index) {
    this.productosAIngresar.splice(index, 1);
  }

  crear(imprimir) {
    this.isSubmit = true;
    this.printing = true;
    this.cdr.detectChanges()
    if (!this.ingresoForm.valid) {
      this.toastr.error('Por favor, complete todos los campos marcados con *');
      return;
    }
    if (!this.productosAIngresar.length) {
      this.toastr.error('Por favor, ingrese productos')
      return;
    }
    if (this.ioe === 'Ingreso') {
      let ingreso = {
        "form": this.ingresoForm.value,
        "productosTotales": this.productosAIngresar,
        "idIngreso": this.id,
        "idHistorial": this.informacion.datos.idhistorial,
        "productosAnteriores": this.productosAnteriores,
        "usuario": this.usuario
      }
      this.historialService.actualizarIngreso(ingreso).subscribe(() => {
        if (imprimir) {
          this.printTable();
          this.printing = false;
        }
        this.ingresoForm.reset()
        this.productosAIngresar = [];
        this.modalRef.close();
        this.isSubmit = false;

      })
    } else {
      let egreso = {
        "form": this.ingresoForm.value,
        "productosTotales": this.productosAIngresar,
        "idEgreso": this.id,
        "idHistorial": this.informacion.datos.idhistorial,
        "productosAnteriores": this.productosAnteriores,
        "usuario": this.usuario
      }
      this.historialService.actualizarEgreso(egreso).subscribe(() => {
        if (imprimir) {
          this.printTable();
          this.printing = false;
        }
        this.isSubmit = false;
        this.ingresoForm.reset()
        this.productosAIngresar = [];
        this.modalRef.close();
      })
    }
  }

  searchItems() {
    const prev = this.mdbTableProductos.getDataSource();
    if (!this.searchText) {
      this.mdbTableProductos.setDataSource(this.previous);
      this.productos = this.mdbTableProductos.getDataSource();
    }
    if (this.searchText) {
      this.productos = this.mdbTableProductos.searchLocalDataByMultipleFields(this.searchText, this.searchItemsTableName);
      this.mdbTableProductos.setDataSource(prev);
    }
  }

  close() {
    this.modalRef.close();
  }

  agregarProducto(producto) {
    let newProducto: IProductosXIngreso = new IProductosXIngreso();
    newProducto.idproducto = producto.idproducto;
    newProducto.nombre = producto.nombre;
    this.productosAIngresar.push(newProducto);
  }

  onChange(event) {
    let cliente: any = this.clientes.filter((cliente: any) => cliente.idcliente === event.target.value)
    this.ingresoForm['controls'].direccion.setValue(cliente[0].direccion);
    this.ingresoForm['controls'].telefono.setValue(cliente[0].telefono);
    this.ingresoForm['controls'].cliente.setValue(cliente[0].idcliente);
  }

  printTable() {
    window.print();
  }

  crearIngreso(imprimir) {
    let ingreso = {
      "ingresoForm": this.ingresoForm.value,
      "productosAIngresar": this.productosAIngresar
    }
    this.productServices.crearIngreso(ingreso).subscribe(() => {
      if (imprimir) {
        this.printTable();
      }
      this.ingresoForm.reset()
      this.productosAIngresar = [];
    })
  }

  onPropertyChange(value) {
    console.log(value);
  }

  get ingresoPallets() {
    return this.ingresoForm.get('pallets');
  }

  get ingresoCantidad() {
    return this.ingresoForm.get('cantidad');
  }
  get ingresoCliente() {
    return this.ingresoForm.get('cliente');
  }

  get ingresoDireccion() {
    return this.ingresoForm.get('direccion');
  }

  get ingresoTelefono() {
    return this.ingresoForm.get('telefono');
  }

  get ingresoTipoPropiedad() {
    return this.ingresoForm.get('tipoPropiedad');
  }

  get ingresoChapa() {
    return this.ingresoForm.get('chapa');
  }

  get ingresoHabSenasa() {
    return this.ingresoForm.get('habSenasa');
  }

  get ingresoPrecintos() {
    return this.ingresoForm.get('precintos');
  }

  get ingresoChofer() {
    return this.ingresoForm.get('chofer');
  }

  get ingresoDni() {
    return this.ingresoForm.get('dni');
  }

  get ingresoObservaciones() {
    return this.ingresoForm.get('observaciones');
  }

  reset() {
    this.ingresoForm.reset();
  }

  altaProducto() {
  }

  removerProduct(clickEvent, i) {
    this.productosAIngresar.splice(i, 1);
    clickEvent.preventDefault();
    clickEvent.stopPropagation();
  }

  recuperarProductos() {
    this.productServices.recuperarTodos().subscribe((datos: Array<Productos>) => {
      this.productos = datos;
      this.mdbTableProductos.setDataSource(this.productos);
      this.previous = this.mdbTableProductos.getDataSource();
    });
  }

  recuperarClientes() {
    this.proveedorService.recuperarTodos().subscribe((datos: Array<Clientes>) => {
      this.proveedores = datos;
    });
    this.clienteService.recuperarTodos().subscribe((datos: Array<Clientes>) => {
      this.clientes = datos

    })
  }



}
