<!--Navbar-->
<div class="d-print-none">
    <mdb-navbar #nav SideClass="navbar navbar-expand-lg navbar-light white">

        <!-- Navbar brand -->
        <mdb-navbar-brand>
            <a class="navbar-brand" href="#">Navbar</a>
        </mdb-navbar-brand>

        <links>
            <!-- Links -->
            <ul class="navbar-nav mr-auto">
                <!-- Dropdown -->
                <li class="nav-item dropdown mega-dropdown" mdbDropdown>
                    <a mdbDropdownToggle class="nav-link dropdown-toggle">News</a>
                    <div class="dropdown-menu mega-menu row z-depth-1">
                        <div class="row">
                            <div class="col-md-5 col-xl-3 sub-menu mt-5 mb-5 pl-4">
                                <ul class="list-unstyled mx-4 pl-0 dark-grey-text">
                                    <li class="sub-title text-uppercase mt-sm">
                                        <a class="menu-item" href="">Laptops</a>
                                    </li>
                                    <li class="sub-title text-uppercase">
                                        <a class="menu-item" href="">Phones</a>
                                    </li>
                                    <li class="sub-title text-uppercase">
                                        <a class="menu-item" href="">Lifestyle</a>
                                    </li>
                                    <li class="sub-title text-uppercase">
                                        <a class="menu-item" href="">Technology</a>
                                    </li>
                                    <li class="sub-title text-uppercase">
                                        <a class="menu-item" href="">Design</a>
                                    </li>
                                </ul>
                            </div>
                            <!-- <div class="col-md-7 col-xl-9">
                            <div class="row">
                                <div class="col-xl-6 mt-5 mb-4 pr-5 clearfix d-none d-block">
                                    <h6 class="sub-title p-sm mb-4 text-uppercase font-weight-bold dark-grey-text">Featured</h6>
                                    <div class="view overlay pb-3">
                                        <img src="https://mdbootstrap.com/img/Photos/Horizontal/Work/6-col/img%20(42).jpg" class="img-fluid z-depth-1" alt="First sample image">
                                        <div class="mask rgba-white-slight flex-center">
                                            <p></p>
                                        </div>
                                    </div>
                                    <h4 class="mb-2">
                                        <a class="news-title" href="">Lorem ipsum dolor sit amet, consectetur isum adipisicing elit.</a>
                                    </h4>
                                    <p class="font-small text-uppercase text-muted">By
                                        <a class="m-sm" href="#!">Anna Doe</a> - July 15, 2017</p>
                                </div>
                                <div class="col-xl-6 mt-5 mb-4 pr-5 clearfix d-none d-xl-block">
                                    <h6 class="sub-title p-sm mb-4 text-uppercase font-weight-bold dark-grey-text">Recent</h6>
                                    <div class="news-single">
                                        <div class="row">
                                            <div class="col-md-4">
                                                <div class="view overlay z-depth-1">
                                                    <img src="https://mdbootstrap.com/img/Photos/Horizontal/Work/6-col/img%20(43).jpg" class="img-fluid" alt="Minor sample post image">
                                                    <div class="mask rgba-white-slight flex-center">
                                                        <p></p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-8">
                                                <a class="news-title smaller mb-1" href="">Duis aute irure dolor reprehenderit in voluptate.</a>
                                                <p class="font-small text-uppercase text-muted">July 14, 2017</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="news-single">
                                        <div class="row">
                                            <div class="col-md-4">
                                                <div class="view overlay z-depth-1">
                                                    <img src="https://mdbootstrap.com/img/Photos/Horizontal/Work/6-col/img%20(44).jpg" class="img-fluid" alt="Minor sample post image">
                                                    <div class="mask rgba-white-slight flex-center">
                                                        <p></p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-8">
                                                <a class="news-title smaller mb-1" href="">Tempore autem reiciendis iste nam dicta.</a>
                                                <p class="font-small text-uppercase text-muted">July 14, 2017</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="news-single">
                                        <div class="row">
                                            <div class="col-md-4">
                                                <div class="view overlay z-depth-1">
                                                    <img src="https://mdbootstrap.com/img/Photos/Horizontal/Work/6-col/img%20(41).jpg" class="img-fluid" alt="Minor sample post image">
                                                    <div class="mask rgba-white-slight flex-center">
                                                        <p></p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-8">
                                                <a class="news-title smaller mb-1" href="">Eligendi dicta sunt amet, totam ea recusandae.</a>
                                                <p class="font-small text-uppercase text-muted">July 14, 2017</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        </div>
                    </div>
                </li>
            </ul>
            <!-- Links -->

        </links>
        <!-- Collapsible content -->

    </mdb-navbar>
    <!--/.Navbar-->
</div>