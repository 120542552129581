export interface IEgreso {
    bultos: number;
    kgxBultos: number;
    descripcion: string;
    tipoDeEnvase: string;
    temp: string;
    amparadoxSanitarioN: number;
}

export class IProductosXEgreso {
    idproducto: number = null;
    nombre: string = null;
    bultos: string = null;
    kgXBultos: string = null;
    descripcion: string = null;
    esCongelado: boolean = null;
    tipoDeEnvase: string = null;
    temp: string = null;
    amparadoXSanitarioN: string = null;
}