import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MdbTableDirective } from 'angular-bootstrap-md';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ClientesService } from 'src/app/services/clientes.service';
import { ToastrService } from 'ngx-toastr';
import { Clientes } from 'src/app/interfaces/clientes';

@Component({
  selector: 'clientes',
  templateUrl: './clientes.component.html',
  styleUrls: ['./clientes.component.scss']
})
export class ClientesComponent implements OnInit {
  headElements = ['ID', 'Nombre', 'CUIT', 'Dirección', 'Teléfono', 'Acciones'];
  clientes: Array<Clientes> = [];
  searchItemsTableName: Array<string> = ['nombre', 'idcliente', 'direccion', 'cuit']
  clientesForm: FormGroup;
  searchText: string = '';
  previous: string;
  full = false;
  type = 'Guardar';
  clienteBaja: any;
  showLogoImage = false;
  usuario = JSON.parse(localStorage.getItem('usuario'));
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective;

  constructor(private clientesServices: ClientesService, private toastr: ToastrService, private cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.recuperarClientes();
    this.clientesForm = new FormGroup({
      idcliente: new FormControl(null),
      nombre: new FormControl('', Validators.required),
      direccion: new FormControl(''),
      cuit: new FormControl(''),
      telefono: new FormControl(''),
    });
  }

  searchItems() {
    const prev = this.mdbTable.getDataSource();
    if (!this.searchText) {
      this.mdbTable.setDataSource(this.previous);
      this.clientes = this.mdbTable.getDataSource();
    }
    if (this.searchText) {
      this.clientes = this.mdbTable.searchLocalDataByMultipleFields(this.searchText, this.searchItemsTableName);
      this.mdbTable.setDataSource(prev);
    }
  }

  onEdit(el) {
    console.log(el);
    this.clientesForm.controls['idcliente'].setValue(el.idcliente);
    this.clientesForm.controls['nombre'].setValue(el.nombre);
    this.clientesForm.controls['direccion'].setValue(el.direccion);
    this.clientesForm.controls['cuit'].setValue(el.cuit);
    this.clientesForm.controls['telefono'].setValue(el.telefono);
  }

  printTable() {
    this.showLogoImage = true;
    this.cdr.detectChanges()
    window.print();
    this.showLogoImage = false;
  }

  get clientesABMNombre() {
    return this.clientesForm.get('nombre');
  }

  get clientesABMTelefono() {
    return this.clientesForm.get('telefono');
  }

  get clientesABMDireccion() {
    return this.clientesForm.get('direccion');
  }

  get clientesABMCuit() {
    return this.clientesForm.get('cuit');
  }

  reset() {
    this.clientesForm.reset();
  }

  changeType(type) {
    this.type = type;
  }

  altaCliente() {
    if (this.type == 'Guardar') {
      let values = this.clientesForm.value;
      delete values.idproducto;
      this.clientesServices.alta(this.clientesForm.value).subscribe((datos: Clientes) => {
        this.toastr.success('', `El cliente ${datos.nombre} fue guardado exitosamente`);
        this.recuperarClientes();
        this.reset();
      });
    } else if (this.type == 'Actualizar') {
      this.clientesServices.update(this.clientesForm.value).subscribe(() => {
        this.toastr.success('', `El cliente fue actualizado exitosamente`);
        this.recuperarClientes();
        this.reset();
      });
    } else if (this.type == 'Baja') {
      this.clienteBaja.desactivado = 1;
      this.clientesServices.update(this.clienteBaja).subscribe(() => {
        this.toastr.success('', `El cliente fue desactivado exitosamente`);
        this.recuperarClientes();
        this.reset();
      });
    } else if (this.type == 'Alta') {
      this.clienteBaja.desactivado = 0;
      this.clientesServices.update(this.clienteBaja).subscribe(() => {
        this.toastr.success('', `El cliente fue activado exitosamente`);
        this.recuperarClientes();
        this.reset();
      });
    }
  }

  asignarModificacion(cliente) {
    this.clienteBaja = cliente;
  }


  recuperarClientes() {
    this.clientesServices.recuperarTodos().subscribe((datos: Array<Clientes>) => {
      this.clientes = datos;
      this.mdbTable.setDataSource(this.clientes);
      this.previous = this.mdbTable.getDataSource();
    });
  }
}