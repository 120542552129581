<app-sidenav [usuario]="usuario"></app-sidenav>
<div class="text-center" [ngClass]="{ showLogoImage: !showLogoImage }">
  <img
    src="../../../assets/images/logoNegro.jpg"
    style="width: 20%"
    class="img-fluid"
    alt="Responsive image"
  />
</div>
<div>
  <div class="container">
    <h1 class="text-white text-center d-print-none">Proveedores</h1>

    <div>
      <div class="container" style="background-color: white">
        <div class="row d-print-none">
          <div class="col-md-12 mx-auto">
            <div class="md-form">
              <input
                type="text"
                [(ngModel)]="searchText"
                (input)="searchItems()"
                class="form-control"
                id="search"
                mdbInput
              />
              <label for="search">Buscar</label>
            </div>
          </div>
        </div>
        <table mdbTable class="z-depth-1" id="tablePrint">
          <thead>
            <tr>
              <th
                *ngFor="let head of headElements; let i = index"
                [ngClass]="{ 'd-print-none': head == 'Acciones' }"
                scope="col"
                class="text-center"
              >
                {{ head }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let el of clientes; let i = index"
              class="text-center"
              [ngClass]="{ desactivado: el.desactivado == 1 }"
            >
              <th scope="row">{{ el.idproveedor }}</th>
              <td class="black-text">{{ el.nombre }}</td>
              <td>{{ el.cuit }}</td>
              <td>{{ el.direccion }}</td>
              <td>{{ el.telefono }}</td>
              <td class="actions d-print-none">
                <div>
                  <mdb-icon
                    mdbTooltip="Editar Cliente"
                    placement="left"
                    (click)="
                      changeType('Actualizar'); onEdit(el); altaModal.show()
                    "
                    fas
                    icon="user-edit"
                    class="blue-text pr-3 pointer"
                  ></mdb-icon>
                </div>
                <div *ngIf="el.desactivado == 0">
                  <mdb-icon
                    mdbTooltip="Desactivar Cliente"
                    placement="right"
                    (click)="asignarModificacion(el); bajaModal.show()"
                    fas
                    icon="user-alt-slash"
                    class="red-text pr-3 pointer"
                  ></mdb-icon>
                </div>
                <div *ngIf="el.desactivado == 1">
                  <mdb-icon
                    mdbTooltip="Activar Cliente"
                    placement="right"
                    (click)="asignarModificacion(el); activarModal.show()"
                    fas
                    icon="user-check"
                    class="green-text pr-3 pointer"
                  ></mdb-icon>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="alta d-print-none">
        <button
          type="button"
          mdbBtn
          color="primary"
          class="relative waves-light"
          (click)="changeType('Guardar'); altaModal.show()"
          mdbWavesEffect
        >
          Agregar nuevo proveedor
        </button>
      </div>
    </div>
  </div>
  <div class="actionsTable d-print-none">
    <button
      type="button"
      mdbBtn
      color="primary"
      class="relative waves-light"
      (click)="printTable()"
      mdbWavesEffect
    >
      Imprimir lista de clientes
    </button>
  </div>
</div>

<!-- INICIO MODAL ALTA PRODUCTO -->
<div
  mdbModal
  #altaModal="mdbModal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myaltaModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="close pull-right"
          aria-label="Close"
          (click)="altaModal.hide()"
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body mx-3">
        <div class="md-form mb-5">
          <input
            type="text"
            id="form-nombre"
            [formControl]="clientesABMNombre"
            class="form-control"
            mdbInput
            mdbValidate
          />
          <label for="form-nombre">Nombre</label>
          <mdb-error
            *ngIf="
              clientesABMNombre.invalid &&
              (clientesABMNombre.dirty || clientesABMNombre.touched)
            "
          >
          </mdb-error>
          <mdb-success
            *ngIf="
              clientesABMNombre.valid &&
              (clientesABMNombre.dirty || clientesABMNombre.touched)
            "
          >
          </mdb-success>
        </div>

        <div class="md-form mb-4">
          <input
            mdbTooltip="Dirección"
            placement="top"
            type="text"
            id="form-direccion"
            [formControl]="clientesABMDireccion"
            class="form-control"
            mdbInput
            mdbValidate
          />
          <label for="form-precio">Dirección</label>
          <mdb-error
            *ngIf="
              clientesABMDireccion.invalid &&
              (clientesABMDireccion.dirty || clientesABMDireccion.touched)
            "
          >
          </mdb-error>
          <mdb-success
            *ngIf="
              clientesABMDireccion.valid &&
              (clientesABMDireccion.dirty || clientesABMDireccion.touched)
            "
          >
          </mdb-success>
        </div>

        <div class="md-form mb-4">
          <input
            mdbTooltip="CUIT"
            placement="top"
            NumericInput
            [latestInputValue]="clientesABMCuit"
            type="text"
            id="form-cuit"
            [formControl]="clientesABMCuit"
            class="form-control"
            mdbInput
            mdbValidate
          />
          <label for="form-precio">CUIT</label>
          <mdb-error
            *ngIf="
              clientesABMCuit.invalid &&
              (clientesABMCuit.dirty || clientesABMCuit.touched)
            "
          >
          </mdb-error>
          <mdb-success
            *ngIf="
              clientesABMCuit.valid &&
              (clientesABMCuit.dirty || clientesABMCuit.touched)
            "
          >
          </mdb-success>
        </div>
        <div class="md-form mb-4">
          <input
            mdbTooltip="Teléfono"
            placement="top"
            NumericInput
            [latestInputValue]="clientesABMTelefono"
            type="text"
            id="form-telefono"
            [formControl]="clientesABMTelefono"
            class="form-control"
            mdbInput
            mdbValidate
          />
          <label for="form-precio">Teléfono</label>
          <mdb-error
            *ngIf="
              clientesABMTelefono.invalid &&
              (clientesABMTelefono.dirty || clientesABMTelefono.touched)
            "
          >
          </mdb-error>
          <mdb-success
            *ngIf="
              clientesABMTelefono.valid &&
              (clientesABMTelefono.dirty || clientesABMTelefono.touched)
            "
          >
          </mdb-success>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            mdbBtn
            color="secondary"
            class="waves-light"
            aria-label="Close"
            (click)="this.reset(); altaModal.hide()"
            mdbWavesEffect
          >
            Cancelar
          </button>
          <button
            type="button"
            mdbBtn
            color="primary"
            class="relative waves-light"
            (click)="altaCliente(); altaModal.hide()"
            mdbWavesEffect
          >
            {{ type }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- FIN MODAL ALTA PRODUCTO -->

<!-- INICIO MODAL BAJA PRODUCTO -->
<div
  mdbModal
  #bajaModal="mdbModal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="mybajaModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="close pull-right"
          aria-label="Close"
          (click)="bajaModal.hide()"
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body mx-3">
        <p>
          Desea desactivar el proveedor? Al hacerlo no podra realizar ingresos o
          egresos al mismo.
        </p>
        <div class="modal-footer">
          <button
            type="button"
            mdbBtn
            color="secondary"
            class="waves-light"
            aria-label="Close"
            (click)="this.reset(); bajaModal.hide()"
            mdbWavesEffect
          >
            No
          </button>
          <button
            type="button"
            mdbBtn
            color="primary"
            class="relative waves-light"
            (click)="changeType('Baja'); altaCliente(); bajaModal.hide()"
            mdbWavesEffect
          >
            Sí
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- FIN MODAL BAJA PRODUCTO -->

<!-- INICIO MODAL BAJA PRODUCTO -->
<div
  mdbModal
  #activarModal="mdbModal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myactivarModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="close pull-right"
          aria-label="Close"
          (click)="activarModal.hide()"
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body mx-3">
        <p>
          Desea activar el proveedor? Al hacerlo podra realizar ingresos o
          egresos al mismo.
        </p>
        <div class="modal-footer">
          <button
            type="button"
            mdbBtn
            color="secondary"
            class="waves-light"
            aria-label="Close"
            (click)="this.reset(); activarModal.hide()"
            mdbWavesEffect
          >
            No
          </button>
          <button
            type="button"
            mdbBtn
            color="primary"
            class="relative waves-light"
            (click)="changeType('Alta'); altaCliente(); activarModal.hide()"
            mdbWavesEffect
          >
            Sí
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- FIN MODAL BAJA PRODUCTO -->
