import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  url: string;
  constructor(private http: HttpClient) {
    this.url = window.location.origin === 'http://localhost:4200' ? 'http://localhost/database/' : 'https://sistema.ichibansa.com.ar/database/';
  }

  busqueda(busqueda) {
    return this.http.post(`${this.url}search/post.php`, JSON.stringify(busqueda));
  }

  getNextId(tipo) {
    return this.http.post(`${this.url}getId/getLastId.php`, JSON.stringify({ tipo: tipo }));
  }

}
