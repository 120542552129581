<!-- Material form login -->
<div class="login-container">
    <!--<div class="text-center logo">
        <img src="../../../assets/images/logoNegro.jpg" style="width: 20%" class="img-fluid" alt="Responsive image" />
    </div>-->
    <div class="left-side">
    </div>
    <div class="login-form">
        <div class="text-center logo">
            <img src="../../../assets/images/logo.png" style="width: 50%" class="img-fluid" alt="Responsive image" />
        </div>
        <mdb-card>
            <mdb-card-header class="info-color white-text text-center py-4">
                <h5>
                    <strong>Ingresar</strong>
                </h5>
            </mdb-card-header>
            <mdb-card-body class="px-lg-5 pt-0">
                    <div class="md-form">
                        <input type="email" id="materialLoginFormEmail" [(ngModel)]="login.nombre" class="form-control" mdbInput>
                        <label for="materialLoginFormEmail">Usuario</label>
                    </div>
                    <div class="md-form">
                        <input type="password" id="materialLoginFormPassword" [(ngModel)]="login.password" class="form-control" mdbInput>
                        <label for="materialLoginFormPassword">Contraseña</label>
                    </div>
                    <div class="d-flex justify-content-around">
                    </div>
                    <button mdbBtn color="info" outline="true" rounded="true" (click)="enter()" block="true"
                        class="my-4 waves-effect z-depth-0" mdbWavesEffect type="submit">
                        Ingresar
                    </button>
            </mdb-card-body>
        </mdb-card>
    </div>
</div>