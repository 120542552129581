import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UsuariosService {

  url : string;
  constructor(private http: HttpClient) {
    this.url = window.location.origin === 'http://localhost:4200' ?  'http://localhost/database/' : 'https://sistema.ichibansa.com.ar/database/';
  }
  

  recuperarTodos() {
    return this.http.get(`${this.url}usuarios/get.php`);
  }

  alta(usuario) {
    return this.http.post(`${this.url}usuarios/post.php`, JSON.stringify(usuario));    
  }

  baja(codigo:number) {
    return this.http.get(`${this.url}usuarios/baja.php?codigo=${codigo}`);
  }
  
  desactivar(usuario) {
    return this.http.post(`${this.url}usuarios/desactivar.php`, JSON.stringify(usuario));    
  } 

  activar(usuario) {
      return this.http.post(`${this.url}usuarios/activar.php`, JSON.stringify(usuario));    
  } 

  update(usuario) {
    return this.http.put(`${this.url}usuarios/update.php`, JSON.stringify(usuario));    
  }

  seleccionar(codigo:number) {
    return this.http.get(`${this.url}usuarios/seleccionar.php?codigo=${codigo}`);
  }
}