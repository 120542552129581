import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class ClientesService {
  url : string;
  constructor(private http: HttpClient) {
    this.url = window.location.origin === 'http://localhost:4200' ?  'http://localhost/database/' : 'https://sistema.ichibansa.com.ar/database/';
   }

  recuperarTodos() {
    return this.http.get(`${this.url}clientes/get.php`);
  }

  alta(cliente) {
    return this.http.post(`${this.url}clientes/post.php`, JSON.stringify(cliente));    
  }

  baja(codigo:number) {
    return this.http.get(`${this.url}clientes/baja.php?codigo=${codigo}`);
  }
  
  desactivar(cliente) {
    return this.http.post(`${this.url}clientes/desactivar.php`, JSON.stringify(cliente));    
  } 

  activar(cliente) {
      return this.http.post(`${this.url}clientes/activar.php`, JSON.stringify(cliente));    
  } 

  update(cliente) {
    return this.http.put(`${this.url}clientes/update.php`, JSON.stringify(cliente));    
  }

  seleccionar(codigo:number) {
    return this.http.get(`${this.url}clientes/seleccionar.php?codigo=${codigo}`);
  }
}