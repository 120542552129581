<!-- SideNav slide-out button -->
<div class="sidenav" >
  <a
    (click)="sidenav.toggle()"
    mdbBtn
    color="primary"
    class="p-3 button-collapse"
  >
    <mdb-icon fas icon="bars"></mdb-icon>
  </a>
  <!--/. SideNav slide-out button -->

  <!-- Sidebar navigation -->
  <mdb-side-nav #sidenav class="fixed darken-4 d-print-none" [fixed]="true">
    <!-- Logo -->
    <li>
      <div class="logo-wrapper waves-light">
        <a href="#"
          ><img
            src="../../../assets/images/logo.png"
            class="img-fluid flex-center"
        /></a>
      </div>
    </li>
    <!--/. Logo -->
    <!--/.Search Form-->
    <!-- Side navigation links -->
    <li>
      <ul class="collapsible collapsible-accordion">
        <mdb-accordion [multiple]="false" aria-multiselectable="false">
          <mdb-accordion-item class="no-collase" >
            <mdb-accordion-item-head mdbWavesEffect >
             Productos
            </mdb-accordion-item-head>
            <mdb-accordion-item-body>
            </mdb-accordion-item-body>
          </mdb-accordion-item>

           <mdb-accordion-item class="no-collase" >
            <mdb-accordion-item-head mdbWavesEffect >
              <a routerLink="/productos" mdbWavesEffect>    -- Stock Propios</a>
            </mdb-accordion-item-head>
            <mdb-accordion-item-body>
            </mdb-accordion-item-body>
          </mdb-accordion-item>

            <mdb-accordion-item class="no-collase" >
            <mdb-accordion-item-head mdbWavesEffect >
              <a routerLink="/productos/clientes" mdbWavesEffect>    -- Stock de Clientes</a>
            </mdb-accordion-item-head>
            <mdb-accordion-item-body>
            </mdb-accordion-item-body>
          </mdb-accordion-item>

          <mdb-accordion-item class="no-collase" >
            <mdb-accordion-item-head mdbWavesEffect >
             <a routerLink="/productos/ingreso" mdbWavesEffect>    -- Ingreso</a>
            </mdb-accordion-item-head>
            <mdb-accordion-item-body>
            </mdb-accordion-item-body>
          </mdb-accordion-item>

          <mdb-accordion-item class="no-collase" >
            <mdb-accordion-item-head mdbWavesEffect >
              <a routerLink="/productos/egreso" mdbWavesEffect>    -- Egreso</a>
            </mdb-accordion-item-head>
            <mdb-accordion-item-body>
            </mdb-accordion-item-body>
          </mdb-accordion-item>

          <mdb-accordion-item class="no-collase" *ngIf="user.rol !== 'capataz'">
            <mdb-accordion-item-head mdbWavesEffect >
              <a routerLink="/clientes">
                Clientes
              </a>
            </mdb-accordion-item-head>
            <mdb-accordion-item-body>
            </mdb-accordion-item-body>
          </mdb-accordion-item>

          <mdb-accordion-item class="no-collase" *ngIf="user.rol !== 'capataz'">
            <mdb-accordion-item-head mdbWavesEffect >
              <a routerLink="/proveedores">
                Proveedores
              </a>
            </mdb-accordion-item-head>
            <mdb-accordion-item-body>
            </mdb-accordion-item-body>
          </mdb-accordion-item>

          <mdb-accordion-item class="no-collase" *ngIf="user.rol !== 'capataz'">
            <mdb-accordion-item-head mdbWavesEffect >
              <a routerLink="/historial">
                Historial
              </a>
            </mdb-accordion-item-head>
            <mdb-accordion-item-body>
            </mdb-accordion-item-body>
          </mdb-accordion-item>

          <mdb-accordion-item class="no-collase" *ngIf="user.rol !== 'capataz'">
            <mdb-accordion-item-head mdbWavesEffect >
              <a routerLink="/usuarios">
                Usuarios
              </a>
            </mdb-accordion-item-head>
            <mdb-accordion-item-body>
            </mdb-accordion-item-body>
          </mdb-accordion-item>

          <mdb-accordion-item class="no-collase" (click)="logOut()">
            <mdb-accordion-item-head mdbWavesEffect >
              <a>
                Salir
              </a>
            </mdb-accordion-item-head>
            <mdb-accordion-item-body>
            </mdb-accordion-item-body>
          </mdb-accordion-item>
        </mdb-accordion>
        
      </ul>
    </li>
    <!--/. Side navigation links -->
    <div class="sidenav-bg mask-strong"></div>
  </mdb-side-nav>
  <!--/. Sidebar navigation -->
</div>
