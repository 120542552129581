import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Busqueda } from 'src/app/interfaces/busqueda';
import { Clientes } from 'src/app/interfaces/clientes';
import { Productos } from 'src/app/interfaces/productos';
import { ClientesService } from 'src/app/services/clientes.service';
import { ProductosService } from 'src/app/services/productos.service';
import { SearchService } from 'src/app/services/search.service';

@Component({
  selector: 'app-prod-clientes',
  templateUrl: './prod-clientes.component.html',
  styleUrls: ['./prod-clientes.component.scss']
})

export class ProdClientesComponent implements OnInit {



  constructor(private clientService: ClientesService, private toastrService: ToastrService, private searchService: SearchService, private productServices: ProductosService) { }
  headElements: Array<string> = ['Cliente', 'Producto', 'Stock Fresco', 'Stock Congelado'];
  clientes: Array<Clientes>;
  productos: Array<Productos>;
  busquedaActiva: Busqueda = {};
  respuesta: any;
  usuario = JSON.parse(localStorage.getItem('usuario'));

  ngOnInit(): void {
    this.clientService.recuperarTodos().subscribe((data: Array<Clientes>) => {
      this.clientes = data;
    })
    this.productServices.recuperarTodos().subscribe((data: Array<Productos>) => {
      this.productos = data;
    })
  }

  buscar() {
    /*if (!(!!this.busquedaActiva.idproducto) && !(!!this.busquedaActiva.idCliente)) {
      return this.toastrService.error('', 'Debe seleccionar al menos una opcion de busqueda.')
    }*/
    let busqueda = {
      idcliente: this.busquedaActiva.idCliente?.idcliente || null,
      idproducto: this.busquedaActiva.idproducto?.idproducto || null
    }
    this.searchService.busqueda(busqueda).subscribe((data) => {
      this.respuesta = data;
      console.log(this.respuesta)
    })
  }

}
