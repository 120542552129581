
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';

import { MDBBootstrapModule, ModalModule } from 'angular-bootstrap-md';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { LoginComponent } from './components/login/login.component';
import { ProductosComponent } from './components/productos/productos.component';
import { MDBBootstrapModulePro } from 'ng-uikit-pro-standard';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { MenuComponent } from './components/menu/menu.component';
import { FooterComponent } from './components/footer/footer.component';
import { NumericInput } from './directives/numberic-input.directive';
import { ToastrModule } from 'ngx-toastr';
import { ClientesComponent } from './components/clientes/clientes.component';
import { IngresoComponent } from './components/productos/ingreso/ingreso.component';
import { EgresoComponent } from './components/productos/egreso/egreso.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ProveedorComponent } from './components/proveedor/proveedor.component';
import { HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HistorialComponent } from './components/historial/historial.component';
import { ProdClientesComponent } from './components/prod-clientes/prod-clientes.component';
import { CanEnter, GuestGuard } from 'src/guest,guard';
import { UsuariosComponent } from './components/usuarios/usuarios.component';
import { ModalComponent } from './components/historial/modal/modal.component';
import { MdbModalModule } from 'mdb-angular-ui-kit/modal';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ProductosComponent,
    SidenavComponent,
    MenuComponent,
    FooterComponent,
    NumericInput,
    ClientesComponent,
    IngresoComponent,
    EgresoComponent,
    ProveedorComponent,
    HistorialComponent,
    ProdClientesComponent,
    UsuariosComponent,
    ModalComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MDBBootstrapModule.forRoot(),
    MDBBootstrapModulePro.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    NgSelectModule,
    ToastrModule.forRoot(),
    MdbModalModule
  ],
  providers: [{ provide: LocationStrategy, useClass: PathLocationStrategy }, GuestGuard, CanEnter],
  bootstrap: [AppComponent]
})
export class AppModule { }
