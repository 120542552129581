import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import {LoginComponent } from './components/login/login.component'
import { ProductosComponent } from './components/productos/productos.component';
import { ClientesComponent } from './components/clientes/clientes.component';
import { IngresoComponent } from './components/productos/ingreso/ingreso.component';
import { EgresoComponent } from './components/productos/egreso/egreso.component';
import { ProveedorComponent } from './components/proveedor/proveedor.component';
import { ProdClientesComponent } from './components/prod-clientes/prod-clientes.component';
import { HistorialComponent } from './components/historial/historial.component';
import { CanEnter, GuestGuard } from 'src/guest,guard';
import { UsuariosComponent } from './components/usuarios/usuarios.component';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'productos', component: ProductosComponent, canActivate: [GuestGuard]},
  { path: 'productos/ingreso', component: IngresoComponent, canActivate: [GuestGuard]},
  { path: 'productos/clientes', component: ProdClientesComponent, canActivate: [GuestGuard]},
  { path: 'productos/egreso', component: EgresoComponent, canActivate: [GuestGuard]},
  { path: 'clientes', component: ClientesComponent, canActivate: [GuestGuard, CanEnter]},
  { path: 'usuarios', component: UsuariosComponent, canActivate: [GuestGuard]},
  { path: 'proveedores', component: ProveedorComponent, canActivate: [GuestGuard, CanEnter]},
  { path: 'historial', component: HistorialComponent, canActivate: [GuestGuard, CanEnter]}
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
